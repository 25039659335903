<template>
    <div>
        <Agenda></Agenda>
    </div>
</template>
<script>
 import Agenda from '../../components/Agenda.vue'
export default {
    components : {
        Agenda
    }
}
</script>