<template>
  <div v-if="ultimiVisti.length>0">
    <span style="font-style: italic;">Visti di Recente</span>
    <b-row >
      <b-col md="1">
        <button @click="scrollLeft" class="scroll-button"><img width=20 src="../assets/arrow-gray-left.png"/></button>
      </b-col>
      <b-col md="10">
      <b-row class="flex-nowrap overflow-hidden" ref="rowRef">
        <b-card
          v-bind:key="data.index"
          v-for="data in prodotti"
          no-body
          style="min-width: 300px; max-width: 300px;  padding: 0"
          @click="navigaDettaglioo(data.id)"
        >
          <b-row no-gutters>
            <b-col md="5" class="d-flex align-items-center">
              <b-card-img
                :src="data.imgs[0] + '?' + $session.get('secret')"
                alt="Responsive image2"
                class="rounded-0"
              ></b-card-img>
            </b-col>
            <b-col md="7">
              <b-card-body class="d-flex flex-column">
                <div
                  v-if="data.postazione != ''"
                  class="postazione mt-auto mb-1"
                  style="margin-top: 0"
                >
                  {{ data.postazione }}
                </div>
                <div v-else class="NOpostazione mt-auto mb-1">-</div>
                <div class="mb-1">{{ data.titolo }}</div>
                <div class="mb-1" style="font-size: 0.5em">
                  {{ data.descrizione }}
                </div>
                <div style="position: absolute; bottom: 0; left: 50%; transform: translateX(-50%);">{{ data.prezzo | valuta }}</div>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-col>
      <b-col md="1">
        <button @click="scrollRight" class="scroll-button"><img width=20 src="../assets/arrow-gray-right.png"/></button>

      </b-col>
      

    </b-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      ultimiVisti: [],
      prodotti: [],
      isLoading: false,
    };
  },
  components: {},
  mounted() {
    this.ultimiVisti = this.$store.state.recentViewsProductId;
    const stringaId = this.ultimiVisti.map((item) => item.id).join(",");
    this.isLoading = true;
    console.log(stringaId);
    axios
      .get(
        this.$store.state.settings.URL_SERVER +
          "API/v1.php?cerca&filtraUltimiVisti=" +
          stringaId
      )
      .then((response) => {
        console.log(response);
        this.prodotti = response.data.prodotti;
        this.isLoading = false;
        console.log("x");
        console.log(this.prodotti);
        this.prodotti.sort((a, b) => {   
          const idA = this.ultimiVisti.findIndex(item => item.id === a.id);
          const idB = this.ultimiVisti.findIndex(item => item.id === b.id);
          return idA - idB;
        });
        prodotti.forEach(prodotto => {
          const correspondingUltimo = this.ultimiVisti.find(item => item.id === prodotto.id);
          if (correspondingUltimo) {
            prodotto.link = correspondingUltimo.link;
          }
        });

      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    navigaDettaglioo(id) {
      this.$router.push({
        name: "categorie",
        params: { livello: "4", genitore: id },
      });
    },
    scrollLeft() {
      const row = this.$refs.rowRef;
      row.scrollLeft -= 300; // Imposta la quantità di scorrimento desiderata
    },
    scrollRight() {
      const row = this.$refs.rowRef;
      row.scrollLeft += 300; // Imposta la quantità di scorrimento desiderata
    },
    toFormData: function (obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    sendAlert(state) {
      if (state == "success") {
        this.showAlertSuccess = true;
        setTimeout(() => (this.showAlertSuccess = false), 4000);
      } else if (state == "fail") {
        this.showAlertFail = true;
        setTimeout(() => (this.showAlertFail = false), 4000);
      }
    },
  },
};
</script>
<style scoped>
.scroll-container {
  overflow: hidden;
}

.scroll-button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  background: transparent;
  border: none;
  color: #333; /* Cambia colore a seconda delle tue esigenze */
}
</style>