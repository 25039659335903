import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    settings: {},
    admin: false,
    recentViewsProductId: [],
  },
  mutations: {
    impostaSetting(state, payload) {
      state.settings = payload;
      //  console.log(state.settings.URL_SERVER);
    },
    impostaAdmin(state, payload) {
      state.admin = payload;
      //  console.log(state.settings.URL_SERVER);
    },
    aggiungiRecentViewProductId(state, product) {
      console.log("qui1")
      // Aggiunge un nuovo ID alla lista
      state.recentViewsProductId.unshift(product);
      console.log("qui2")
      // Controlla se la lunghezza supera 10 e rimuove l'elemento più vecchio
      if (state.recentViewsProductId.length > 20) {
        state.recentViewsProductId.pop();
      }
      console.log("qui3")
    },
  },
  actions: {},
  modules: {},
});
